let counter = 0;

let scrolling = false;

let previousScroll = 0;

//smooth scrolling

$('a[href*="#"]').on('click', function (e) {
    e.preventDefault();
    $('body').removeClass('noScroll');
    $('html, body').animate({
        scrollTop: $($(this).attr('href')).offset().top - 100
    }, 1500);
    $('body').removeClass('overflow');
    app.closeMenu();
});


var app = {}


// SCROLLING EVENTS

// scroll event 
app.scrollingEvent = () => {
    $(window).scroll(function () {
        scrolling = true;

    })
}

// run functions that have to do with scrolling
app.scrollEffects = function () {
    setInterval(function () {
        if (scrolling = true) {
            scrolling = false;


        } // end of if scrolling
    }, 250) // end of interval
};

// HEADER SCROLL FUNCTION
app.desktopHeader = () => {

    let headerTop = $("header").offset().top;
    let headerBottom = $("header").offset().top + $("header").outerHeight();
    let screenBottom = $(window).scrollTop() + window.innerHeight;
    let screenTop = $(window).scrollTop();

    let currentScroll = $(window).scrollTop();

    //scrolling up 
    if (currentScroll < previousScroll) {
        //scrolling up and not at the top of the screen
        if (screenTop > 50) {
            $('.headerMenu').removeClass('hideHeaderMenu').addClass('menuDesktopScroll')
        }

        // at the top of screen
        if (screenTop < 49) {
            $('.headerMenu').removeClass('menuDesktopScroll hideHeaderMenu');
        }
    }

    //scrolling down 
    if (currentScroll > previousScroll) {
        // not at the top of the screen
        if (screenTop > 400) {
            $('.headerMenu').addClass('hideHeaderMenu');
        }

        // at the top of the screen
        if (screenTop < 399 && !menuOpen) {
            if (screenTop < 100) {
                $(menu).removeClass('menuDesktopScroll')
            } else {
                $(menu)
                    .addClass('menuDesktopScroll ')
                    .removeClass('hideHeaderMenu');
            }
        }
    } // end of scrolling down

    previousScroll = currentScroll;


}

app.menuButton = () => {
    $('.menuButton').on('click', function(e) {
        e.preventDefault();
        
        let overlayOpen = $('.overlay').hasClass('overlayActive');

        if(!overlayOpen) {
            $('.overlay').css('display', 'block');
            setTimeout(() => {
                $('.overlay').addClass('overlayActive')
                $('.gunta').css('opacity', '0')
            },500)

            $('.menuOpen').addClass('menuButtonActive')
        } else if (overlayOpen) {
            app.closeMenu();
        }

    })
}

//close menu function
app.closeMenu = () => {
    $('.overlay').removeClass('overlayActive')
    setTimeout(() => {
        $('.overlay').css('display', 'none')
        $('.gunta').css('opacity', '1')
    },500)

    $('.menuOpen').removeClass('menuButtonActive')
}


// load galleries
app.gallery = () => {
    const gallery = require('./module/galleryData.js');

    $.when(
        gallery.map((item) => {
            let galleryName = item.name;
            let galleryTitle = $('.gallery').hasClass(galleryName)
    
            if (galleryTitle) {
                let images = item.images;
                let credits = `<p class="photoCredit">${item.credits}</p>`
                images.map((pic) => {
                    let pictureHTML = `<img src="${pic.src}" alt="${pic.alt}" class="${pic.class}">`
                    
                    $('.' + galleryName).append(pictureHTML)
                   
                }) // end of loop
    
                $('.' + galleryName).append(credits)
            }
    
        })// end of loop
    ).then(function() {
        setTimeout(() => {
            $('.bluePic').css('opacity', '1');
        }, 400);

        setTimeout(() => {
            $('.pinkPic').css('opacity', '1');
        }, 800);

        setTimeout(() => {
            $('.yellowPic').css('opacity', '1');
            $('.photoCredit').css('opacity', '1')
        }, 1500);
    })
}

app.loadAbout = () => {
    const about = require('./module/aboutData.js');

    let text = about[0].text;

    text.map((item) => {
        $('.aboutText').append(`<p>${item}</p>`)
    })
}

app.loadCreate = () => {
    const create = require('./module/createData.js')

    let text = create.text;

    text.map((item) => {
           
        $('.createText').append(`<p>${item}</p>`)
        
        })
}

app.readMore = () => {
    $('.readMore').on('click', function(e) {
        e.preventDefault();
        $('.hiddenText').css('display', 'block');
        $('.readMore').css('opacity', '0');
        setTimeout(() => {
            $('.hiddenText').css('opacity', '1')
            $('.readMore').css('display', 'none')
        },300)
    })
}

app.loadClients = () => {
    const companies = require('./module/clientsData.js')

    let opera = []
    let theatre = []
    let venues = []
    opera = companies.Opera
    theatre = companies.Theatre;
    venues = companies.Venues;

    opera.map((item) => {
        $('.opera').append(`<a target="_blank" style="display: block" href="${item.link}">${item.company}</a>`)
    })

    theatre.map((item) => {
        $('.theatre').append(`<a target="_blank" style="display: block" href="${item.link}">${item.company}</a>`)
    })

    venues.map((item) => {
        $('.venue').append(`<a target="_blank" style="display: block" href="${item.link}">${item.company}</a>`)
    })

}

app.clients = () => {
    $.when(
        app.loadClients()
    ).then(function() { 
        // make sure venues with no link are unclickable
        let noLinks = $('.noClick').parent();
        console.log(noLinks)
        
        $(noLinks).addClass('noClick')
    })
}



app.loadReviews = () => {
    const reviews = require('./module/reviewsData.js');

    let review = []
    let articles = []
    let artist = []
    let audience = []

    review = reviews.Reviews;
    articles = reviews.Articles;
    artist = reviews.Artist;
    audience = reviews.Audience;

    review.map((item) => {
        $('.review').append(`<p style="margin-top: 25px">${item.text}</p><p class="--bold">${item.author}</p>`)
    })

    articles.map((item) => {
        $('.articles').append(`<p style="margin-top: 25px">${item.text}</p><p class="--bold">${item.author}</p>`)
    })

    artist.map((item) => {
        $('.artist').append(`<p style="margin-top: 25px">${item.text}</p><p class="--bold">${item.author}</p>`)
    })

    audience.map((item) => {
        $('.audience').append(`<p style="margin-top: 25px">${item.text}</p><p class="--bold">${item.author}</p>`)
    })
}

//  ********* FORM **************

app.submitForm = () => {
    $('#form').submit(function (e) {
        e.preventDefault();
        var $form = $('#form');
        var ty = $('.thankYou')
        $.post($form.attr("action"), $form.serialize()).then(function () {

            $form.css('opacity', '0')

            setTimeout(() => {
                $(ty).css('display', 'block');
                $form.css('display', 'none')
            }, 300)

            setTimeout(() => {
                $(ty).css('opacity', 1)
            },400)

            $('html, body').animate({
                scrollTop: $('#contact').offset().top - 100
            }, 600, 'swing');
        });
    });
}



app.init = function () {
    app.gallery();
    app.menuButton();
    app.loadAbout();
    app.loadCreate();
    app.clients();
    app.loadReviews();
    app.readMore();
    app.submitForm();
}


$(function () {
    app.init();
});