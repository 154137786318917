module.exports = {
    "Opera": [
            {
                company: "Amarillo Opera",
                link: "https://www.amarilloopera.org"
            },
            {
                company: "Anchorage Opera",
                link: "https://anchorageopera.org"
            },
            {
                company: "Arizona Opera	",
                link: "https://azopera.org"
            },
            {
                company: "Canadian Opera Company",
                link: "https://www.coc.ca"
            },
            {
                company: "Cleveland Opera",
                link: "https://theclevelandopera.org"
            },
            {
                company: "Dallas Opera",
                link: "https://dallasopera.org"
            },
            {
                company: "Eugene Opera",
                link: "https://eugeneopera.org"
            },
            {
                company: "Fort Worth Opera",
                link: "https://www.fwopera.org"
            },
            {
                company: "Glimmerglass Opera",
                link: "https://glimmerglass.org"
            },
            {
                company: "Hawaii Opera Theatre",
                link: "https://www.hawaiiopera.org"
            },
            {
                company: "Indianapolis Opera",
                link: "https://www.indyopera.org"
            },
            {
                company: "Kentucky Opera",
                link: "https://www.kyopera.org"
            },
            {
                company: "LA Opera",
                link: "https://www.laopera.org"
            },
            {
                company: "Latvian National Opera",
                link: "https://www.opera.lv/en/home"
            },
            {
                company: "New Orleans Opera",
                link: "https://neworleansopera.org"
            },
            {
                company: "New York City Opera",
                link: "https://nycopera.com"
            },
            {
                company: "<span class='noClick'>Opera Mississauga</span>",
                link: ""
            },
            {
                company: "Opera Atelier",
                link: "https://www.operaatelier.com"
            },
            {
                company: "Pittsburgh Opera",
                link: "https://www.pittsburghopera.org"
            },
            {
                company: "L’Opéra de Quebec",
                link: "https://operadequebec.com/en/"
            },
            {
                company: "<span class='noClick'>Capitol Opera Sacramento</span>",
                link: ""
            },
            {
                company: "Seattle Opera",
                link: "https://www.seattleopera.org"
            },
            {
                company: "Toledo Opera",
                link: "https://www.toledoopera.org"
            },
            {
                company: "Vancouver Opera",
                link: "https://www.vancouveropera.ca"
            },
            {
                company: "Virginia Opera",
                link: "https://vaopera.org"
            },
            {
                company: "<span class='noClick'>Queen of Puddings Music Theatre</span>",
                link: ""
            },
            {
                company: "Tapestry Opera",
                link: "https://tapestryopera.com/"
            }
        ],
    "Theatre": [
            {
                company: "Teatro Biondo Stabile di Palermo",
                link: "https://www.teatrobiondo.it"
            },
            {
                company: "Centre International de Creations Theatrales CICT",
                link: "https://www.theatre-contemporain.net/"
            },
            {
                company: "Schaubühne am LehninerPlatz",
                link: "https://www.schaubuehne.de/de/start/index.html"
            },
            {
                company: "Maly Drama Theatre of St. Petersburg",
                link: "https://www.maly.ru/en"
            },
            {
                company: "Grupo Galpão",
                link: "https://grupogalpao.com.br"
            },
            {
                company: "Shinjuku Ryozanpaku",
                link: "https://sites.google.com/site/shinjukuryozanpaku/"
            },
            {
                company: "Théâtre français de Toronto",
                link: "https://theatrefrancais.com/"
            },
            {
                company: "Japan Foundation",
                link: "https://www.jpf.go.jp/e/project/culture/perform/"
            },
            {
                company: "Tangled Art + Disability",
                link: "https://tangledarts.org/"
            }, 
            {
                company: "<span class='noClick'>Teatro Escambray, Cuba</span>",
                link: ""
            }
        ],
    "Venues": [
            {
                company: "Minnesota Symphony",
                link: "https://minnesotaorchestra.org"
            },
            {
                company: "Artpark",
                link: "https://www.artpark.net"
            },
            {
                company: "Sesame Street Live",
                link: "https://www.sesamestreetlive.com"
            },
            {
                company: "Toronto Symphony Orchestra",
                link: "https://www.tso.ca"
            },
            {
                company: "University of Toronto, Faculty of Music",
                link: "https://music.utoronto.ca"
            },
            {
                company: "Edinburgh Festival",
                link: "https://www.edfringe.com/"
            },
            {
                company: "Melbourne Festival",
                link: "https://melbournefringe.com.au/"
            },
            {
                company: "Glenn Gould School at The Royal Conservatory",
                link: "https://www.rcmusic.com/ggs/home"
            },
            {
                company: "Brooklyn Academy of Music ",
                link: "https://www.bam.org/"
            }
        ]
}