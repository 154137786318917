module.exports = {
    header: "How we create surtitles",
    video : {
        "link" : "<iframe width='560' height='315' src='https://www.youtube.com/embed/R8Hp0ECS5Zg' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>"
    },
    text: [ 
  
                "<span class='--bold' style='margin-top:40px'>My goals in translating libretti into surtitiles is to:</span>",
                "1 / Accurately reflect YOUR production, in all its shades and colours",
                "2 / Always use native speakers of the original language to edit and proof for subtle nuances of language, use another editor (non-operatic, English as a first language) for an unbiased but authoritative  perspective of the English content",
                "3 / Program cues to change at varying speeds to reflect the music, thereby rendering the titles unobtrusive and fluid",
                "I’ve had 38 years’ experience (and still counting!) in writing surtitles, because I had the good fortune to be part of the team that invented SURTITLES™ in 1983 at the Canadian Opera Company, Toronto, Canada.",
                "<span class='--bold' style='margin-top:40px'>Surtitling FAQs</span>",
                "1 / Who invented surtitles?",
                "Lotfi Mansouri (General Director in 1983) and John Leberg (Director of Operations) at the Canadian Opera Company.",
                "2 / Which was the first opera to be surtitled and why?",
                "<a href='' style='display: block;' class='readMore --bold --red'>Read More</a>",
                "<span class='hiddenText'><span class='--italic'>Elektra</span> by Richard Strauss, on January 21, 1983. Lotfi Mansouri chose this  German opera because the action is mainly psychological, not physical. Sure, there are entrances and exits, but the drama that unfolds is psychological, and that is difficult to convey, also difficult to understand if you don’t know German. Paired with <span class='--italic'>Elektra</span> was <span class='--italic'>Il Coronazione di Poppea</span> (Monteverdi), a rarely performed baroque opera with a lot of recitative to move the action along; difficult to understand if you don’t know Italian and are not accustomed to hearing and understanding Baroque Italian. ",
                "<span class='hiddenText'>3 / What was the critical response to surtitles after the first performance?",
                "<span class='hiddenText'>One critic called them “the plague from Canada”. The late James Levine said, “Over my dead body.” Generally speaking, the critics hated them, but the audiences loved them. They could understand what was going on! Now pretty well everyone uses surtitles - in opera and drama, on YouTube and video clips, even in ads on the screen pre-show (I shudder at the thought!).",
                "<span class='hiddenText'>4 / Why call them surtitles?",
                "<span class='hiddenText'>They were invented in Canada which is  a bilingual country. Surtitles are TITLES projected on a screen ON the proscenium arch.  SUR in French =on, above; TITLES in English = titres in French. Surtitles reflect Canada’s bilingualism.",
                "<span class='hiddenText'>5 / What are other names for surtitles?",
                "<span class='hiddenText'>Supertitles, OpTrans (opera translations), OpCaps (Opera capsulizations), surtitres (French), sopratitoli (Italian), Übertiteln (German), titri (Latvian), subtitles (a misnomer) and so on…",
                "<span class='hiddenText'>6 / Why so many names?",
                "<span class='hiddenText'>My guess is that in the beginning, the Canadian Opera Company trademarked the word SURTITLES™. Other companies were wary of incurring costs if they used the COC’s word; therefore they made up a different name. ",
                "<span class='hiddenText'>7 / Why didn’t the Canadian Opera Company patent surtitles?",
                "<span class='hiddenText'>One can take out a patent for something new – a tool, a machine, a recipe, a THING. The COC invented a process of simultaneous translations for live production by applying existing technology in a new, innovative manner. One can’t apply a patent to a process.",
                "<span class='hiddenText'>8 / Why not use the translations in the score?",
                "<span class='hiddenText'>Those are called singing translations, and are written so that the opera can be sung in a language other than the original. Essentially, a singing translation is guided by the music; every note needs a syllable. A translation for a surtitle is more concerned with conveying the meaning artfully. Consider 'To be or not to be' in English. Translated in Latvian in Jānis Kalniņš’ opera <span class='--italic'>Hamlets</span>, it comes out as 'Būt vai nebūt'.  Or from <span class='--italic'>La Traviata</span>, consider 'T’amo, Alfredo' in Italian, to 'Ich liebe Dich, Alfredo' in German. Quite a difference. Good luck to the singing translator. ",
                "<span class='hiddenText'>9 / Why not sing an opera in translation?",
                "<span class='hiddenText'>The libretto (text) of an opera or drama in the original language sounds best. Singers learn operas in the original language and are loathe to learn them in other languages, say Latvian, if the only place they will sing it in Latvian is in Latvia. Singers work globally. Operas are aesthetically most pleasing in the original. ",
                "<span class='hiddenText'>Benjamin Britten (English) is the only composer I know of who “allowed” his operas to be translated to the language of the country where the opera was to be performed. Even so, nowadays his operas are most often sung in English, even in Europe. ",
                "<span class='hiddenText'>10 / Why have titles in English for an opera sung in English?",
                "<span class='hiddenText'>In North America, opera houses are BIG!  Multipurpose performance centres are popular, not only in North America but around the world, and these stages are often unkind to the opera singer. In Toronto, the Canadian Opera Company used to perform in the O’Keefe Centre, a multipurpose building, that was affectionately called “the house that beer built”, O'Keefe being a brewery; or, during the production of <span class='--italic'>La Fanciulla del West</span>, the OK Corral!! The libretti of Britten, for instance, are replete with English words no longer in common usage (think of ship or  boarding school terminology). Or think of the rapid fire Gilbert and Sullivan rhyming couplets, or of Phillip Glass’ extended tones. The singer can enunciate until the cows come home (<span class='--italic'>La Fanciulla</span>, again!) but certain passages will be a challenge, to say the least.",
                "<span class='hiddenText'>11 / Why create new titles for every show? ",
                "<span class='hiddenText'>The words don’t change so why change the titles? Let’s take <span class='--italic'>Carmen</span> as an example. The simple answer is because one size does not fit all. Many operas are subject to cuts for various reasons, not least of which is timing. Some are updated and the language needs to reflect the director’s vision, all the while being true to the original. Translations are copyrighted; you need permission from the author to use them. ",
                "<span class='hiddenText'>12 / Why not just put the titles on a loop and “let them rip”?",
                "<span class='hiddenText'>Because surtitles are used for LIVE performance, and no two performances are alike. That is the thrill of live performance; that is why we go to the theatre. Live performances have varying running times every single performance. I remember one production of <span class='--italic'>Lohengrin</span> - it was the last one of the run - and the tenor had a plane to catch so that performance was ten minutes shorter than all the previous ones.</span>",
                "<span class='hiddenText'>13 / How many operas have you translated?</span>",
                "<span class='hiddenText'>The short answer is, 'Too many to list'. I have created surtitles for all the top ten operas, some, like <span class='--italic'>La Bohème</span> and <span class='--italic'>Carmen</span>, multiple times. Some favorites include Wagner's <span class='--italic'>Ring Cycle</span>; Harry Somer's <span class='--italic'>Louis Riel</span>; all of Queen of Pudding's productions, particularly <span class='--italic'>Svadba</span>; all of Théâtre français de Toronto's productions, particularly <span class='--italic'>Tremblay</span> and <span class='--italic'>Molière</span>; all of WorldStage productions; <span class='--italic'>Bunraku</span> with the Japan Foundation; and others.</span>",
                "<span class='--bold' style='margin-top:40px'>Surtitles Fun Facts</span>",
                "1 / Surtitles were invented in 1983 at the Canadian Opera Company before: ",
                "Cellphones – land lines only / Email – one relied on memos / Netflix / Voicemail – a receptionist answered the land line / DVDs, Laserdiscs / Livestreams / Laptops / YouTube and more. Titles would be typed on an IBM Selectric typewriter, sent to an A/V company to photograph and return in 35mm glass-mounted slides. Then the slides would be put in slide carousels, alternating between 3 carousels for each act. The cuer would shout Left  Centre Right to the IATSE operator, anticipating the time it would take to change to next carousel. Three were used because that provided space for 240 slides, which was enough for an average act. You definately wanted to avoid changing three sets of carousels live while the show was going on. Too nerve-wracking. ",
                "2 / An average opera has about 500-600 cues",
                "3 / Colours in the libretto are problematic if the cast doesn’t match the stipulated choices in hair, skin, costume etc.",
                "4 / Peter Sellars the opera director is not to be confused with Peter Sellers, the comic actor",
                "5 / The Canadian Opera Company chose a sans serif font because the titles are usually two lines of isolated text and the serif seemed too fussy and florid. They started out with Century Gothic and Ariel, but Peter Sellars, see above, chose Kalinga. Yeah, Peter! Hours of font meetings avoided!"
    ]
}