module.exports = {
    "Reviews": [
            {
                text: `“The Canadians have created something that makes opera understandable and accessible to many who love the music but can’t understand the words. It’s called SURTITLES.”`,
                author: "The New York Times"
            },
            {
                text: `“The Falstaff SURTITLES in Toronto had one magic ingredient - they were FUNNY!!!!!  The audience laughed on cue; the singers continuously responded to their laughter by providing a light, enjoyable, musically complex evening. Brilliant translations that set the stage for true comprehension.”`,
                author: "Sander L. Gilman Professor of Liberal Arts and Medicine, University of Illinois in Chicago"
            }
        ],
    "Articles": [
            {
                text: `“Audiences; What Figaro Really Says”`,
                author: "Meg Cox, The Wall Street Journal, July 6, 1984"
            },
            {
                text: `“Wagner is letter perfect.[Surtitles] prepared by Gunta Dreifelds for Lohengrin were pungent and erudite.”`,
                author: "Bob Pennington, The Sunday Sun, September 18, 1983"
            },
            {
                text: `“When we did the first night of surtitles ...we did more in one night than we had in our previous 37 years of existence.”`,
                author: "Kate Taylor, Globe and Mail, November 15, 2007"
            },
            {
                text: `“Opera Enjoys Its Charmed Life. Devotees Grow in Numbers as They Grow Younger, Too”`,
                author: "James R. Oestreich, The New York Times, April 28, 1997"
            },
            {
                text: `“Forse questo strumento da noi aggiunto servira ad entrare dentro la magia del teatro.” <br> “...i sopratitoli hanno rappresentato una soluzione conveniente...”`,
                author: "Stefano Nardelli, il giornale della musica, n. 299, gennaio 2013"
            }
        ],
    "Artist": [
            {
                text: `“Having been at the birth of SURTITLES in Canada, I am very proud to have witnessed the revolution that has swept the operatic world. Gunta’s work, then as now, is inspired: perfect timing, always succinct and informative, and consistently appropriate to the action, from split-second comic text, to the sensitivity of the dramatic moment.”`,
                author: "Gidon Saks"
            },
            {
                text: `"Bien sûr, les surtitres ont aidé le TfT à se créer une nouvelle clientèle."`,
                author: "Guy Mignault, directeur du Théâtre français de Toronto, 1997 – 2016"
            },
            {
                text: `Gunta’s Übertiteln sind eine wunderbare Erfindung und eine Bereicherung für das Publikum.`,
                author: "Adi Braun, jazz singer, German diction coach"
            }
        ],
    "Audience": [
            {
                text: `“Also I must tell you that the SURTITLES have just changed my whole feeling towards opera.  Previously with most of the operas that I watched, I had a general idea of what was going on and just let the music wash over me.  Now I can really enjoy understanding what is happening.”`,
                author: ""
            },
            {
                text: `“I also greatly appreciate the SURTITLES — they add enormously to my enjoyment of the operas.”`,
                author: ""
            },
            {
                text: `“Your production, cast and SURTITLES added up to such a memorable evening that, three months later, I am still touting <span class='--italic'>Anna Bolena</span>’s praises.”`,
                author: ""
            },
            {
                text: `“…congratulations…heartily endorse the SURTITLES, which were beautifully translated and tastefully projected.”`,
                author: ""
            },
            {
                text: `“However, the main reason for writing is to yell a very big bravo for the SURTITLES.”`,
                author: ""
            }
        ]
}