module.exports = [
    {
        name: "firstGallery", 
        images: [
            {
                src: "assets/1.jpg",
                alt: "four seasons in toronto",
                color: "--pink",
                order: 2,
                class: 'pinkPic'
            },
            {
                src: "assets/2.gif",
                alt: "picture of Luisa Miller Opera",
                color: "--blue",
                order: 1,
                class: "bluePic"
            },
            {
                src: "assets/3.gif",
                alt: "Picture of Il Trovatore Opera",
                color: "--yellow",
                order: 3,
                class: "yellowPic"
            }
        ],
        credits: "Photo Credits (From Top to Bottom) Four Seasons Centre / The Canadian Opera Company's Luisa Miller, 1997. Photo: Brian Campbell / The Canadian Opera Company's Il Trovatore, 1996."
        
    },
    {
        name: "secondGallery",
        images: [
            {
                src: "assets/4.jpg",
                alt: "Picture of Gunta",
                color: "--pink",
                order: 2,
                class: 'pinkPic'
            },
            {
                src: "assets/5.gif",
                alt: "Picture of Salome opera",
                color: "--blue",
                order: 1,
                class: "bluePic"
            },
            {
                src: "assets/6.gif",
                alt: "Oedipus Rex Opera",
                color: "--yellow",
                order: 3,
                class: "yellowPic"
            }
        ],
        credits: "Photo Credits (From Top to Bottom) Teiya Kasahara / The Canadian Opera Company's Salome, 2996. Photo: Gary Beechey / the Canadian Opera Company's Oedipus Rex, 1997. Photo: Gary Beechey"
    },
    {
        name: "thirdGallery",
        images: [
            {
                src: "assets/7.jpg",
                alt: "",
                color: "--pink",
                order: 2,
                class: 'pinkPic'
            },
            {
                src: "assets/8.jpg",
                alt: "",
                color: "--blue",
                order: 1,
                class: "bluePic"
            },
            {
                src: "assets/9.jpg",
                alt: "",
                color: "--yellow",
                order: 3,
                class: "yellowPic"
            }
        ],
        credits: "Photo Credits (From Top to Bottom) The Canadian Opera Company's Peter Grimes, 2014. Photo: Michael Cooper / The Canadian Opera Company's Die Walkür 2018 / THe Canadian Opera Company's La Bohème, 1989. Photo: Gary Beechey"
    }
]