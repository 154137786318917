module.exports = [
    { 
        title: "Who we are & What we do",
        text: [
            "SURTITLES™* projections are a capsulized translation of an opera’s libretto, projected on a screen hung from the proscenium arch of the stage during a live performance. The process was developed by the Canadian Opera Company, and the first production in the world to be presented with SURTITLES™ was the COC’s January 1983 staging of <span class='--italic'>Elektra</span>.",
            "The COC’s original process of SURTITLES™ projection utilized slides and slide projectors. In June, 1991, for the COC’s new production of <span class='--italic'>Così fan tutte</span>, the process was modified to video projection. The video images then were stored on computer diskettes rather than slides to permit quick, low-cost editing and to simplify storage. Now all titles are computer-generated. Each video image consists of one surtitle corresponding to the sung text. Images are numbered and the corresponding number transferred to a vocal score, used in the operation of the video projector. Translations are projected on a medium grey screen on the proscenium of the stage and appear as white lettering on a black background.",
            "Hailed by the Canadian Press as “the barrier-breaker opera needs to grow in the ‘80s,” SURTITLES™ projections are a registered trademark of the COC, and are now a regular feature of the foreign-language operas presented by the Company.",
            "Since they were pioneered by the COC, SURTITLES™ or other similar titling systems have been used by virtually every opera company in North America and Europe, and indeed, the world.",
            "I am Gunta Dreifelds, part of the team that invented SURTITLES™ in 1983 at the Canadian Opera Company in Toronto, Canada, along with Lotfi Mansouri and John Leberg. Since 1987 I have been an independant consultant, retaining the Canadian Opera Company as one of my major clients. I draw from a large group of experts in the fields of projection, screens, IT, and IATSE.",
            "I translate operas into English from the original language, specifically but not exclusively from Italian, German, and French, without infringing on any copyright, for production during live performance. Although SURTITLES™ were invented with opera in mind, I have adapted the process for live theatre as well as concerts. ",
            "We also consult with companies of any and every size, for first time installation, training of projector operator and cuer, and evaluating the many existing technologies to adapt the one most suitable for your company."
        ]
    }
]